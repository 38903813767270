<script>
  /**
   * SvelteBoilerplate
   * © 2020, Hendrik Runte
   **/

  import { version } from './lib/version.js';
  import Header from './components/Header.svelte';
  import Revision from './components/Revision.svelte';
  import { fetchConnectivity } from './lib/fetchConnectivity';
  import { onMount } from 'svelte';

  window.console.debug(
    '%c This is assembled with svelte. ',
    'background-color: fuchsia; color: black;'
  );

  const SupervisedAPIs = [
    {
      api: 'https://sp.hendrikrunte.de/api/connectivity',
      title: 'SP',
      result: false,
      elapsed: 0,
    },
    {
      api: 'https://www.runte-sv.de/api/connectivity',
      title: 'runte-sv.de',
      result: false,
      elapsed: 0,
    },
    {
      api: 'https://daylight.hendrikrunte.de/api',
      title: 'Daylight',
      result: false,
      elapsed: 0,
    },
    {
      api: 'https://rezepte.capricorna.de/api/connectivity',
      title: 'rezepte.capricorna.de',
      result: false,
      elapsed: 0,
    },
    {
      api: 'https://fluglizenz.candybgraveller.cc/api/connectivity',
      title: 'Candy B. Graveller',
      result: false,
      elapsed: 0,
    },
    {
      api: 'https://dev.events.cafelust.de/api/connectivity',
      title: 'CafeLust (DEV)',
      result: false,
      elapsed: 0,
    },
    {
      api: 'https://tempo.hendrikrunte.de/api/connectivity',
      title: 'Tempo (PROD)',
      result: false,
      elapsed: 0,
    },
    {
      api: 'https://dev.tempo.hendrikrunte.de/api/connectivity',
      title: 'Tempo (DEV)',
      result: false,
      elapsed: 0,
    },
  ];

  async function check() {
    SupervisedAPIs.forEach(async (supervised, i) => {
      const timerStart = new Date().getTime();
      const result = await fetchConnectivity(supervised.api);
      window.console.debug(`Checking '${supervised.title}'.`);
      const timerEnd = new Date().getTime();
      if (result.Message) {
        SupervisedAPIs[i].result = true;
      }
      SupervisedAPIs[i].elapsed = timerEnd - timerStart;
    });
  }

  onMount(async () => {
    try {
      check();
      window.setInterval(() => {
        check();
      }, 60000);
    } catch (e) {
      console.debug(e);
    }
  });
</script>

<Header title="Supervisor" subtitle="Active APIs" />
<main>
  {#each SupervisedAPIs as supervised}
    <dl>
      <dt>{supervised.title}:</dt>
      <dd>
        <span class="elapsed" style="margin-right: 0.4em"
          >{supervised.elapsed} ms</span
        >
        {#if supervised.result === true}
          <span class="light greenlight" />
        {:else if supervised.result === false}
          <span class="light redlight" />
        {:else}
          <span class="light nolight" />
        {/if}
      </dd>
    </dl>
  {/each}
</main>

<Revision {version} />

<!--
	Mind the 'global' attribute!
	This is needed to let 'prependData' work.
	See rollup.config.js
-->
<style global lang="scss">@charset "UTF-8";
/**
 * typography
 *
 */
:global(html) {
  font-family: system, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", "Lucida Grande", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 62.5%;
}

:global(body) {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 130%;
}

/**
 * keyframes
 */
@-webkit-keyframes -global-fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opactiy: 1;
  }
}
@keyframes -global-fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opactiy: 1;
  }
}
@-webkit-keyframes -global-slipIn {
  0% {
    opacity: 0.5;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes -global-slipIn {
  0% {
    opacity: 0.5;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@-webkit-keyframes -global-slideInLeft {
  0% {
    opacity: 0.5;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes -global-slideInLeft {
  0% {
    opacity: 0.5;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@-webkit-keyframes -global-slideInRight {
  0% {
    opacity: 0.5;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes -global-slideInRight {
  0% {
    opacity: 0.5;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
/**
 * common
 *
 * All common style settings go here.
 * For font-family and other typographic stuff
 * see _typography.scss
 */
:global(html) {
  box-sizing: border-box;
  scroll-behavior: smooth;
  background-color: #666666;
  color: white;
}

:global(*),
:global(*:before),
:global(*:after) {
  box-sizing: inherit;
}

:global(html),
:global(body) {
  height: 100%;
}

:global(body) {
  margin: 0;
}

:global(.fadein) {
  -webkit-animation: 0.5s ease-out 0s 1 fadeIn;
          animation: 0.5s ease-out 0s 1 fadeIn;
}

:global(h1),
:global(h2),
:global(h3),
:global(h4),
:global(h5),
:global(h6) {
  margin: 0;
}

:global(p) {
  margin: 0 0 1em 0;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}

:global(q) {
  quotes: "»" "«" "›" "‹";
}
:global(q:lang(fr)) {
  quotes: "«" "»" "‹" "›";
}
:global(q:lang(en)) {
  quotes: "“" "”" "‘" "’";
}
:global(q:lang(ch)) {
  quotes: "» " " «" "› " " ‹";
}

:global(blockquote) {
  margin: 0;
  padding: 0;
}

:global(a:-webkit-any-link) {
  text-decoration: none;
}

:global(a:-moz-any-link) {
  text-decoration: none;
}

:global(a:any-link) {
  text-decoration: none;
}

:global(ul),
:global(li),
:global(dl),
:global(dt),
:global(dd) {
  padding: 0;
  margin: 0;
  list-style: none;
}

:global(h1),
:global(h2) {
  line-height: 140%;
}

:global(h2) {
  font-size: 1em;
}

:global(#app) {
  width: 300px;
  margin: 1em auto;
  padding: 1em 1.4em;
  border: 1px solid #666666;
  border-radius: 9px;
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
  width: 300px;
  line-height: 140%;
}

:global(.light) {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #595959;
  box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.5);
}
:global(.light.greenlight) {
  background-color: #00f000;
  box-shadow: 0 0 10px #00f000;
}
:global(.light.redlight) {
  background-color: #f0372d;
  box-shadow: 0 0 10px #f0372d;
}

@-webkit-keyframes -global-spin {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@keyframes -global-spin {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
:global(.progress) {
  height: 4px;
  width: 0%;
  background-color: #00f000;
  box-shadow: 0 0 10px #00f000;
  margin: 0 0 1em 0;
  border-radius: 4px;
  -webkit-animation: 60s linear spin infinite;
          animation: 60s linear spin infinite;
}

:global(main) {
  padding-top: 1em;
}

:global(dl) {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
:global(dl) :global(dt),
:global(dl) :global(dd) {
  padding: 0.1em 0;
}
:global(dl) :global(dt) {
  flex-grow: 1;
}
:global(dl) :global(dd) {
  flex-shrink: 1;
}</style>
