<script>
  export let version;
</script>

<style lang="scss">@charset "UTF-8";
/**
 * typography
 *
 */
html {
  font-family: system, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", "Lucida Grande", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 62.5%;
}

body {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 130%;
}

/**
 * keyframes
 */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opactiy: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opactiy: 1;
  }
}
@-webkit-keyframes slipIn {
  0% {
    opacity: 0.5;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes slipIn {
  0% {
    opacity: 0.5;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@-webkit-keyframes slideInLeft {
  0% {
    opacity: 0.5;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes slideInLeft {
  0% {
    opacity: 0.5;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@-webkit-keyframes slideInRight {
  0% {
    opacity: 0.5;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes slideInRight {
  0% {
    opacity: 0.5;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
/**
 * common
 *
 * All common style settings go here.
 * For font-family and other typographic stuff
 * see _typography.scss
 */
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  background-color: #666666;
  color: white;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

.fadein {
  -webkit-animation: 0.5s ease-out 0s 1 fadeIn;
          animation: 0.5s ease-out 0s 1 fadeIn;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0 0 1em 0;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}

q {
  quotes: "»" "«" "›" "‹";
}
q:lang(fr) {
  quotes: "«" "»" "‹" "›";
}
q:lang(en) {
  quotes: "“" "”" "‘" "’";
}
q:lang(ch) {
  quotes: "» " " «" "› " " ‹";
}

blockquote {
  margin: 0;
  padding: 0;
}

a:-webkit-any-link {
  text-decoration: none;
}

a:-moz-any-link {
  text-decoration: none;
}

a:any-link {
  text-decoration: none;
}

ul,
li,
dl,
dt,
dd {
  padding: 0;
  margin: 0;
  list-style: none;
}

h1,
h2 {
  line-height: 140%;
}

h2 {
  font-size: 1em;
}

#app {
  width: 300px;
  margin: 1em auto;
  padding: 1em 1.4em;
  border: 1px solid #666666;
  border-radius: 9px;
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
  width: 300px;
  line-height: 140%;
}

.light {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #595959;
  box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.5);
}
.light.greenlight {
  background-color: #00f000;
  box-shadow: 0 0 10px #00f000;
}
.light.redlight {
  background-color: #f0372d;
  box-shadow: 0 0 10px #f0372d;
}

@-webkit-keyframes spin {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@keyframes spin {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
.progress {
  height: 4px;
  width: 0%;
  background-color: #00f000;
  box-shadow: 0 0 10px #00f000;
  margin: 0 0 1em 0;
  border-radius: 4px;
  -webkit-animation: 60s linear spin infinite;
          animation: 60s linear spin infinite;
}

main {
  padding-top: 1em;
}

dl {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
dl dt,
dl dd {
  padding: 0.1em 0;
}
dl dt {
  flex-grow: 1;
}
dl dd {
  flex-shrink: 1;
}

section {
  margin-top: 2em;
  display: block;
  color: white;
  font-family: "Courier New", Courier, monospace;
  font-weight: normal;
  font-size: 1em;
  padding: 0.4em 0;
}
section p {
  width: 100%;
  margin: 0 auto;
}</style>

<section name="revision">
  <p>Revision: {version}</p>
</section>
